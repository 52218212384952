import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import StaticSection from "../components/StaticSection"
import Contact from "../components/Contact"

const Uszczerbek = () => {
  const { imageMobile, imagePc } = useStaticQuery(graphql`
    query {
      imageMobile: file(relativePath: { eq: "uszczerbekMobile.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imagePc: file(relativePath: { eq: "uszczerbekPc.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <StaticSection
        title="Lorem ipsum dolor sit amet consectetur"
        desc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat ex
        cupiditate qui vitae animi odit rem aut atque architecto porro amet,"
        img={imageMobile}
        imgPc={imagePc}
        buttonText="Zadzwoń"
        link="tel:883882500"
        sectionTitle="Czy się kwalifikuję?"
        sectionDesc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat ex
        cupiditate qui vitae animi odit rem aut atque architecto porro amet,
        dolorem a dicta perferendis illum maiores voluptatum perspiciatis
        harum?"
        listTitle="Lorem ipsum dolor sit amet:"
        listItems={[
          <p>Lorem ipsum dolor sit amet consectetur</p>,
          <p>Lorem ipsum dolor sit amet consectetur</p>,
          <p>Lorem ipsum dolor sit amet consectetur</p>,
          <p>
            Lorem ipsum dolor sit <span>amet consectetur</span>
          </p>,
          <p>Lorem ipsum dolor sit amet consectetur</p>,
        ]}
        listButton="Zadzwoń"
        listButtonHref="tel:123432535"
      />
      <Contact />
    </Layout>
  )
}

export default Uszczerbek
